import { combineReducers } from "redux"
import authReducer from './authReducer'
import userReducer from './userReducer'
import clientReducer from './clientReducer'
import clientsReducer from './clientsReducer'
import usersReducer from './usersReducer'
import rolesReducer from './rolesReducer'
import twoFAReducer from './twoFAReducer'
import clientDocReducer from './clientDocReducer'
import clientDocsReducer from './clientDocsReducer'
import clientRecordReducer from './clientRecordReducer'

export default combineReducers({
    auth: authReducer,
    user: userReducer,
    client: clientReducer,
    clients: clientsReducer,
    users: usersReducer,
    roles: rolesReducer,
    verifyToken: twoFAReducer,
    clientDoc: clientDocReducer,
    clientDocs: clientDocsReducer,
    clientRecord: clientRecordReducer,
})