import { React, Component } from 'react'
import { connect } from "react-redux"
import * as actions from "../actions"

import NavBar from "./home/NavBar"
import Clients from "./home/clients/Clients"
import NewClient from "./home/clients/NewClient"
import ViewClient from './home/clients/ViewClient'

import Users from "./home/users/Users"
import NewUser from "./home/users/NewUser"
import ViewUser from './home/users/ViewUser'

import Roles from "./home/roles/Roles"
import NewRole from "./home/roles/NewRole"
import ViewRole from "./home/roles/ViewRole"

import { Container, Message } from 'semantic-ui-react'

class App extends Component {

    state = { activeItem: "clients" }

    constructor(props) {
        super(props)
        this.handleChangeItemNavBar = this.handleChangeItemNavBar.bind(this)
        this.handleChangeItemClient = this.handleChangeItemClient.bind(this)
        this.handleChangeItemUser = this.handleChangeItemUser.bind(this)
        this.handleChangeItemRole = this.handleChangeItemRole.bind(this)
        this.handleBackClickedClients = this.handleBackClickedClients.bind(this)
        this.handleBackClickedUsers = this.handleBackClickedUsers.bind(this)
        this.handleBackClickedRoles = this.handleBackClickedRoles.bind(this)
        this.handleCreateClientSuccess = this.handleCreateClientSuccess.bind(this)
        this.handleEditClientSuccess = this.handleEditClientSuccess.bind(this)
        this.handleDeleteClientSuccess = this.handleDeleteClientSuccess.bind(this)
        this.handleCreateUserSuccess = this.handleCreateUserSuccess.bind(this)
        this.handleEditUserSuccess = this.handleEditUserSuccess.bind(this)
        this.handleDeleteUserSuccess = this.handleDeleteUserSuccess.bind(this)
        this.handleCreateRoleSuccess = this.handleCreateRoleSuccess.bind(this)
        this.handleEditRoleSuccess = this.handleEditRoleSuccess.bind(this)
        this.handleDeleteRoleSuccess = this.handleDeleteRoleSuccess.bind(this)
    }

    componentDidMount() {
        this.props.fetchUser()
        this.props.fetchRoles()
        if (!!this.props.verifyToken) {
            window.localStorage.setItem('verified2FA', 'yup')
        }
    }

    componentDidUpdate(previousProps) {
        if (previousProps.roles !== this.props.roles) {
            // unpack permissions
            for (let role of this.props.roles) {
                if (!!this.props.user) {
                    if (!!this.props.user.role) {
                        if (role._id === this.props.user.role.id) {
                            this.setState({ permissions: role.permissions })
                            this.setState({ canExportClientInfoForm: role.canExportClientInfoForm, canFilterByFYE: role.canFilterByFYE, canFilterByMonthOfAgreement: role.canFilterByMonthOfAgreement })
                        }   
                    }
                }
            }
        }
    }

    renderCreateSuccessMessage() {
        if (this.state.showCreateClientSuccess) {
            return (<Container>
                <br />
                <Message success header='New client successfully created.' content='You can find and edit the client on this page.'/>
            </Container>)
        } else if (this.state.showCreateUserSuccess) {
            return (<Container>
                <br />
                <Message success header='New user successfully created.' content='You can find and edit the user on this page.'/>
            </Container>)
        } else if (this.state.showCreateRoleSuccess) {
            return (<Container>
                <br />
                <Message success header='New role successfully created.' content='You can find and edit the role on this page.'/>
            </Container>)
        } else {
            return
        }
    }

    renderEditSuccessMessage() {
        if (this.state.showEditClientSuccess) {
            return (<Container>
                <br />
                <Message success header='Client changes successfully saved' content={this.state.savedClientName} />
            </Container>)
        } else if (this.state.showEditUserSuccess) {
            return (<Container>
                <br />
                <Message success content='User changes successfully saved.'/>
            </Container>)
        } else if (this.state.showEditRoleSuccess) {
            return (<Container>
                <br />
                <Message success content='Role changes successfully saved.'/>
            </Container>)
        } else {
            return
        }
    }

    renderDeleteSuccessMessage() {
        if (this.state.showDeleteClientSuccess) {
            return (<Container>
                <br />
                <Message success content='Client successfully deleted.'/>
            </Container>)
        } else if (this.state.showDeleteUserSuccess) {
            return (<Container>
                <br />
                <Message success content='User successfully deleted.'/>
            </Container>)
        } else if (this.state.showDeleteRoleSuccess) {
            return (<Container>
                <br />
                <Message success content='Role successfully deleted.'/>
            </Container>)
        } else {
            return
        }
    }

    renderContent() {
        switch (this.state.activeItem) {
            case "clients":
                return <Clients onChangeItem={this.handleChangeItemClient} onDeleteSuccess={this.handleDeleteClientSuccess} permissions={this.state.permissions} canFilterByFYE={this.state.canFilterByFYE} canFilterByMonthOfAgreement={this.state.canFilterByMonthOfAgreement}/>
            case "users":
                return <Users onChangeItem={this.handleChangeItemUser} onDeleteSuccess={this.handleDeleteUserSuccess}/>
            case "roles":
                return <Roles onChangeItem={this.handleChangeItemRole} onDeleteSuccess={this.handleDeleteRoleSuccess}/>
            case "new-client":
                return <NewClient onCreateSuccess={this.handleCreateClientSuccess} onCancel={this.handleBackClickedClients} permissions={this.state.permissions}/>
            case "view-client":
                return <ViewClient clientToView={this.state.clientToView} onBackClicked={this.handleBackClickedClients} permissions={this.state.permissions} canExportClientInfoForm={this.state.canExportClientInfoForm}/>
            case "edit-client":
                return <NewClient isEditing clientToEdit={this.state.clientToEdit} onEditSuccess={this.handleEditClientSuccess} onCancel={this.handleBackClickedClients} permissions={this.state.permissions} canExportClientInfoForm={this.state.canExportClientInfoForm}/>
            case "new-user":
                return <NewUser onCreateSuccess={this.handleCreateUserSuccess} onCancel={this.handleBackClickedUsers}/>
            case "view-user":
                return <ViewUser userToView={this.state.userToView} onBackClicked={this.handleBackClickedUsers}/>
            case "edit-user":
                return <NewUser isEditing userToEdit={this.state.userToEdit} onEditSuccess={this.handleEditUserSuccess} onCancel={this.handleBackClickedUsers}/>
            case "new-role":
                return <NewRole roleToEdit={this.state.roleToEdit} onCreateSuccess={this.handleCreateRoleSuccess} onCancel={this.handleBackClickedRoles}/>
            case "view-role":
                return <ViewRole roleToView={this.state.roleToView} onBackClicked={this.handleBackClickedRoles}/>
            case "edit-role":
                return <NewRole isEditing roleToEdit={this.state.roleToEdit} onEditSuccess={this.handleEditRoleSuccess} onCancel={this.handleBackClickedRoles}/>
            default: 
                return
        }
    }

    render() {
        let verified = window.localStorage.getItem('verified2FA')
        if (!this.state.activeItem) {
            return
        }
        if (verified !== 'yup') {
            return
        }
        var name = ""
        if (this.props.user != null) {
            name = this.props.user.name
        }
        return (
            <div>
                <NavBar activeItem={this.state.activeItem} onChangeItem={this.handleChangeItemNavBar} />
                <br />
                <Container><h4>Welcome, {name}</h4></Container>
                {this.renderCreateSuccessMessage()}
                {this.renderEditSuccessMessage()}
                {this.renderDeleteSuccessMessage()}
                {this.renderContent()}
            </div>
        )
    }

    handleChangeItemNavBar(item) {
        this.setState({ activeItem: item, 
                        showCreateClientSuccess: false, showEditClientSuccess: false, showDeleteClientSuccess: false,
                        showCreateUserSuccess: false, showEditUserSuccess: false, showDeleteUserSuccess: false,
                        showCreateRoleSuccess: false, showEditRoleSuccess: false, showDeleteRoleSuccess: false })
    }

    handleChangeItemClient(item, client) {
        if (client) {
            if (item === 'view-client') this.setState({ clientToView: client})
                else if (item === 'edit-client') this.setState({ clientToEdit: client })
        }
        this.setState({ activeItem: item, showCreateClientSuccess: false, showEditClientSuccess: false, showDeleteClientSuccess: false,
                        showCreateUserSuccess: false, showEditUserSuccess: false, showDeleteUserSuccess: false,
                        showCreateRoleSuccess: false, showEditRoleSuccess: false, showDeleteRoleSuccess: false })
    }

    handleChangeItemUser(item, user) {
        if (user) {
            if (item === 'view-user') this.setState({ userToView: user})
                else if (item === 'edit-user') this.setState({ userToEdit: user })
        }
        this.setState({ activeItem: item, showCreateClientSuccess: false, showEditClientSuccess: false, showDeleteClientSuccess: false,
                        showCreateUserSuccess: false, showEditUserSuccess: false, showDeleteUserSuccess: false,
                        showCreateRoleSuccess: false, showEditRoleSuccess: false, showDeleteRoleSuccess: false })
    }

    handleChangeItemRole(item, role) {
        if (role) {
            if (item === 'view-role') this.setState({ roleToView: role})
                else if (item === 'edit-role') this.setState({ roleToEdit: role })
        }
        this.setState({ activeItem: item, showCreateClientSuccess: false, showEditClientSuccess: false, showDeleteClientSuccess: false,
                        showCreateUserSuccess: false, showEditUserSuccess: false, showDeleteUserSuccess: false,
                        showCreateRoleSuccess: false, showEditRoleSuccess: false, showDeleteRoleSuccess: false })
    }

    handleBackClickedClients() {
        this.setState({ activeItem: 'clients' })
    }

    handleBackClickedUsers() {
        this.setState({ activeItem: 'users' })
    }

    handleBackClickedRoles() {
        this.setState({ activeItem: 'roles' })
    }

    handleCreateClientSuccess() {
        this.setState({ activeItem: 'clients', showCreateClientSuccess: true })
    }

    handleEditClientSuccess(name) {
        this.setState({ activeItem: 'clients', showEditClientSuccess: true, savedClientName: name })
    }

    handleDeleteClientSuccess() {
        this.setState({ activeItem: 'clients', showDeleteClientSuccess: true })
    }

    handleCreateUserSuccess() {
        this.setState({ activeItem: 'users', showCreateUserSuccess: true })
    }

    handleEditUserSuccess() {
        this.setState({ activeItem: 'users', showEditUserSuccess: true })
    }

    handleDeleteUserSuccess() {
        this.setState({ activeItem: 'users', showDeleteUserSuccess: true })
    }

    handleCreateRoleSuccess() {
        this.setState({ activeItem: 'roles', showCreateRoleSuccess: true })
    }

    handleEditRoleSuccess() {
        this.setState({ activeItem: 'roles', showEditRoleSuccess: true })
    }

    handleDeleteRoleSuccess() {
        this.setState({ activeItem: 'roles', showDeleteRoleSuccess: true })
    }
}

function mapStateToProps({ user, roles, verifyToken }) {
    return { user, roles, verifyToken }
}

export default connect(mapStateToProps, actions)(App)

