import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "../../../../actions"

import { Container, Button, Input, Transition } from 'semantic-ui-react'

class DocScanQRLanding extends Component {
    state = { showDocHandoverConfirmationPopup: false, showDocReceivedConfirmationPopup: false, visible: true, initials: "" }

    constructor(props) {
        super(props)
        this.handleDoneClicked = this.handleDoneClicked.bind(this)
    }

    componentDidMount() {
        this.props.getDocumentFromID(this.props.match.params.clientID, this.props.match.params.recordID, this.props.match.params.docID)
    }

    handleChangeField = e => {
        const { name, value } = e.target
        this.setState({ [e.target.name]: value})
    }

    render() {
        var str = ""
        var desc = ""
        if (!!this.props.clientDoc) {
            if (this.props.clientDoc.data.doc.description !== "") desc = ' (' + this.props.clientDoc.data.doc.description + ')'
            str = this.props.clientDoc.data.doc.type + desc + ' – ' + this.props.clientDoc.data.clientName
        }
        return (
            <Container style={{ marginTop: 50 }}>
                <h3>Scanning document: {str}</h3>
                <h3>Please enter your initials below to confirm</h3>
                <Input name='initials' value={this.state.initials} placeholder='Your initials here' onChange={this.handleChangeField}></Input>
                <br />
                <Transition animation='shake' duration={150} visible={this.state.visible}>
                    <Button style={{ marginTop: 20 }} color="red" onClick={this.handleDoneClicked}>Done</Button>
                </Transition>
            </Container>
        )
    }

    handleDoneClicked() {
        const { initials } = this.state
        if (initials === "") {
            this.setState((prevState) => ({ visible: !prevState.visible }))
            return
        }
        var arr = []
        arr.push(this.props.clientDoc.data.doc.id)
        let dict = { recordID: this.props.match.params.recordID, docIDs: arr, status: 'Ready to Return', initials }
        this.props.updateDocumentStatus(this.props.match.params.clientID, dict, (success, data) => {
            if (success) {
                alert('Scanning successful – document status changed to Ready to Return. You may now close this page.')
            } else {
                alert('Failure changing document status, please try again')
            }
        })
    }
}

function mapStateToProps({ clientDoc }) {
    return { clientDoc }
}

export default connect(mapStateToProps, actions)(DocScanQRLanding)

