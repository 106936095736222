export const FETCH_USER = 'fetch_user'
export const LOGIN = 'login'
export const TWOFA = 'two_factor_authentication'
export const LOGOUT = 'logout'
export const CREATE_CLIENT = 'create_client'
export const FETCH_CLIENTS = 'fetch_clients'
export const EDIT_CLIENT = "edit_client"
export const EDIT_CLIENT_ADD_DOCUMENT_RECORD = "edit_client_add_document_record"
export const EDIT_CLIENT_UPDATE_DOCUMENT_STATUS = "edit_client_update_document_status"
export const GET_DOCUMENT_BY_ID = "get_document_by_id"
export const FETCH_CLIENT_DOCS = "get_client_docs"
export const GET_RECORD_BY_ID = "get_record_by_id"
export const DELETE_CLIENT = "delete_client"
export const CREATE_USER = 'create_user'
export const FETCH_USERS = 'fetch_users'
export const EDIT_USER = "edit_user"
export const DELETE_USER = "delete_user"
export const CREATE_ROLE = 'create_role'
export const FETCH_ROLES = 'fetch_roles'
export const EDIT_ROLE = "edit_role"
export const DELETE_ROLE = "delete_role"