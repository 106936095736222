import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "../../../actions"
import { fields, departments } from "../../../fields"

import { Container, Breadcrumb, BreadcrumbSection, BreadcrumbDivider, Form, FormField, Divider, Header, Icon, Input, TextArea, Button, 
        TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table, Checkbox, Message, Label } from 'semantic-ui-react'

const labelStyle = {
    marginTop: "5px",
    marginRight: "8px",
    marginBottom: "5px"
}

class ViewRole extends Component {
    state = { role: {}, remarks: "" }

    handleChangeField = e => {
        if (this.state.role[e.target.name] == null) { // make sure that empty fields can't be edited
            var newRole = this.state.role
            newRole[e.target.name] = ""
            this.setState({ role: newRole })
        }
        return
    }

    handleToggleFieldCanView = (e, data) => {
        
    }

    handleToggleFieldCanEdit = (e, data) => {
        
    }

    handleToggleDeptCanView = (e, data) => {
        
    }

    handleToggleDeptCanEdit = (e, data) => {
        
    }

    renderFields(dept) {
        var rows = []
        const isAllReadTrue = allReadTrue(dept, this.props.roleToView.permissions)
        const isAllWriteTrue = allWriteTrue(dept, this.props.roleToView.permissions)
        rows.push(
            <TableRow>
                <TableCell><h4><b>All {dept.deptName} fields</b></h4></TableCell>
                <TableCell><Checkbox toggle name={dept.dept} checked={isAllReadTrue} onChange={this.handleToggleDeptCanView}/></TableCell>
                <TableCell><Checkbox toggle name={dept.dept} checked={isAllWriteTrue} onChange={this.handleToggleDeptCanEdit}/></TableCell>
            </TableRow>
        )
        const fields = getFieldsForDept(dept)
        for (var i = 0; i < fields.length; i++) {
            const field = fields[i]
            const permission = getPermissionForField(field.key, this.props.roleToView.permissions)
            if (permission == null) {
                continue
            }
            rows.push(
                <TableRow key={i}>
                    <TableCell>{field.fieldName}</TableCell>
                    <TableCell><Checkbox toggle name={field.key} checked={permission.read} onChange={this.handleToggleFieldCanView}/></TableCell>
                    <TableCell><Checkbox toggle name={field.key} checked={permission.write} onChange={this.handleToggleFieldCanEdit}/></TableCell>
                </TableRow>
            )
        }
        return rows
    }

    renderDepartments() {
        var arr = []
        for (var i = 0; i < departments.length; i++) {
            const dept = departments[i]
            arr.push(<div>
                <h3>{dept.deptName}</h3>
                <Table striped>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Field Name</TableHeaderCell>
                        <TableHeaderCell width={2}>Can View</TableHeaderCell>
                        <TableHeaderCell width={2}>Can Edit</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                {this.renderFields(dept)}
                </TableBody>
              </Table>
                <br />
            </div>)
        }
        return arr
    }

    renderUsers(users) {
        if (users.length === 0) {
            return ('No users assigned to this role.')
        }
        var rows = []
        for (var i = 0; i < users.length; i++) {
            rows.push(
                <Label style={labelStyle} size="large">
                    {users[i].name}
                </Label>
            )
        }
        return rows
    }

    renderMiscPermissions() {
        return (
            <div>
                <br />
                <Table striped>
                    <TableRow>
                        <TableCell><h4><b>Can Export Client Information Form</b></h4></TableCell>
                        <TableCell width={2}><Checkbox toggle name="" checked={this.props.roleToView.canExportClientInfoForm} onChange={this.handleToggleCanExportClientInfoForm}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><h4><b>Can Filter by FYE</b></h4></TableCell>
                        <TableCell width={2}><Checkbox toggle name="" checked={this.props.roleToView.canFilterByFYE} onChange={this.handleToggleCanFilterByFYE}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><h4><b>Can Filter by Month of Agreement</b></h4></TableCell>
                        <TableCell width={2}><Checkbox toggle name="" checked={this.props.roleToView.canFilterByMonthOfAgreement} onChange={this.handleToggleCanFilterByMonthOfAgreement}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><h4><b>Can Add Client Document Record</b></h4></TableCell>
                        <TableCell width={2}><Checkbox toggle name="" checked={this.props.roleToView.canAddClientDocumentRecord ? this.props.roleToView.canAddClientDocumentRecord : false} onChange={this.handleToggleCanFilterByMonthOfAgreement}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><h4><b>Can Delete Client Document Record</b></h4></TableCell>
                        <TableCell width={2}><Checkbox toggle name="" checked={this.props.roleToView.canDeleteClientDocumentRecord ? this.props.roleToView.canDeleteClientDocumentRecord : false} onChange={this.handleToggleCanFilterByMonthOfAgreement}/></TableCell>
                    </TableRow>
                </Table>
                <br />
            </div>
        )
    }
    
    renderForm() {
        const { name, users, remarks } = this.props.roleToView
        return (
            <Container>
                <Divider horizontal>        
                    <Header as='h4'>
                        <Icon name='info circle'/>
                        Role Details
                    </Header>
                </Divider>
                <Form>
                    <FormField control={Input} label='Name of Role' name='name' value={name} placeholder='' onChange={this.handleChangeField}/>
                    <FormField control={TextArea} rows='2' label='Remarks' name='remarks' value={remarks ? remarks : ""} placeholder='' onChange={this.handleChangeField}/>
                </Form>
                <br /><br />
                <Divider horizontal>        
                    <Header as='h4'>
                        <Icon name='users'/>
                        Role Users
                    </Header>
                </Divider>
                <Form>
                    {this.renderUsers(users)}
                </Form>
                <br /><br />
                <Divider horizontal>        
                    <Header as='h4'>
                        <Icon name='question'/>
                        Role Permissions
                    </Header>
                </Divider>
                {this.renderMiscPermissions()}
                {this.renderDepartments()}
                <br />
            </Container>
        )
    }

    render() {
        return (
            <Container>
                <br />
                <Breadcrumb size='large'>
                    <BreadcrumbSection onClick={this.props.onBackClicked}>Roles</BreadcrumbSection>
                    <BreadcrumbDivider icon='right chevron' />
                    <BreadcrumbSection active>{this.props.roleToView.name}</BreadcrumbSection>
                </Breadcrumb>
                <br /><br />
                <Message info header='This page is view-only.' content='Its contents are uneditable.'/>
                <br />
                {this.renderForm()}
                <br /><br />
                <Button onClick={this.props.onBackClicked}>Back to Roles Page</Button>      
                <br /><br /><br /><br /><br /><br /><br /><br />
            </Container>
        )
    }
}

function mapStateToProps({ user }) {
    return { user }
}

function getFieldsForDept(dept) {
    var arr = []
    for (var i = 0; i < fields.length; i++) {
        const field = fields[i]
        if (field.department === dept.dept) {
            arr.push(field)
        }
    }
    return arr
}

function getPermissionForField(key, permissions) {
    if (permissions == null) {
        return {}
    }
    for (var i = 0; i < permissions.length; i++) {
        const permission = permissions[i]
        if (key === permission.key) {
            return permission
        }
    }
}

function getKeysFromDept(dept) {
    var keys = []
    for (var i = 0; i < fields.length; i++) {
        const field = fields[i]
        if (field.department === dept) {
            keys.push(field.key)
        }
    }
    return keys
}

function allReadTrue(dept, permissions) {
    if (permissions == null) {
        return false
    }
    let keys = getKeysFromDept(dept.dept)
    for (var i = 0; i < permissions.length; i++) {
        const permission = permissions[i]
        for (var x = 0; x < keys.length; x++) {
            if (permission.key === keys[x] && permission.read === false) {
                return false
            }
        }
    } 
    return true
}

function allWriteTrue(dept, permissions) {
    if (permissions == null) {
        return false
    }
    let keys = getKeysFromDept(dept.dept)
    for (var i = 0; i < permissions.length; i++) {
        const permission = permissions[i]
        for (var x = 0; x < keys.length; x++) {
            if (permission.key === keys[x] && permission.write === false) {
                return false
            }
        }
    } 
    return true
}

export default connect(mapStateToProps, actions)(ViewRole)