import { React, Component } from 'react'
import { connect } from "react-redux"
import * as actions from "../../../actions"

import { Container, Button, TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table, Confirm, Icon, Grid, GridColumn, Input} from 'semantic-ui-react'

class Roles extends Component {
    state = { }

    constructor(props) {
        super(props)
        this.handleDeleteClicked = this.handleDeleteClicked.bind(this)
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this)
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this)
    }

    componentDidMount() {
        this.props.fetchRoles()
        this.props.fetchUsers()
    }

    renderTableRows() {
        var data = []
        if (this.state.isSearching) {
            for (var x = 0; x < this.props.roles.length; x++) {
                let role = this.props.roles[x]
                if (role.name != null) {
                    if (role.name.toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(role)
                        continue
                    }
                }
                if (role.users != null) {
                    if (role.users.toString().toLowerCase().includes(this.state.searchString.toLowerCase())) {
                        data.push(role)
                        continue
                    }
                }
            }
        } else {
            data = this.props.roles
        }
        for (var z = 0; z < data.length; z++) {
            if (data[z].name === 'superadmin') {
                data.splice(z, 1)
            }
        }
        var rows = []
        for (let role of data) {
            var users = []
            for (let user of role.users) {
                users.push(user.name)
            }
            rows.push(
                <TableRow key={role._id}>
                    <TableCell collapsing>
                        <Button circular basic icon='edit' onClick={this.handleEditClicked.bind(this, role)}/>
                        <Button circular basic icon='eye' onClick={this.handleViewClicked.bind(this, role)}/>
                        <Button circular basic icon onClick={this.handleDeleteClicked.bind(this, role)}>
                            <Confirm open={this.state.showDeleteConfirmationPopup} onCancel={this.handleDeleteCancel} onConfirm={this.handleDeleteConfirm} 
                            header="Confirmation" content={this.state.deleteConfirmContentString} cancelButton="Cancel" confirmButton="Delete"/>
                            <Icon name="trash alternate" />
                        </Button>
                    </TableCell>
                    <TableCell>{role.name}</TableCell>
                    <TableCell>{users.join(', ')}</TableCell>
                </TableRow>
            )
        }
        return rows
    }

    renderTable() {
        if (this.props.roles == null) {
            return
        } else {
            return (
                <Table celled striped compact>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell></TableHeaderCell>
                        <TableHeaderCell width={7}>Name</TableHeaderCell>
                        <TableHeaderCell width={7}>Users</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                {this.renderTableRows()}
                </TableBody>
              </Table>
            )
        }
    }

    render() {
        return (
            <Container>
                <br /><br />
                <Grid>
                    <GridColumn floated='left' width={5}><h1>Manage Roles</h1></GridColumn>
                    <GridColumn width={6}><Input fluid action={{ icon: 'search' }} placeholder='Search role...' onChange={this.handleChangeSearchField} /></GridColumn>
                    <GridColumn floated='right' width={5}>
                        <Button color='red' floated='right' onClick={() => { this.props.onChangeItem("new-role")}}>Create New Role</Button>
                    </GridColumn>
                </Grid>
                {this.renderTable()}
                <br /><br />
            </Container>
        )
    }

    handleChangeSearchField = e => {
        const { value } = e.target
        if (value === "") {
            this.setState({ isSearching: false })
        } else {
            this.setState({ isSearching: true, searchString: value })
        }
    }

    handleEditClicked(role) {
        this.props.onChangeItem('edit-role', role)
    }

    handleViewClicked(role) {
        this.props.onChangeItem('view-role', role)
    }

    handleDeleteClicked = role => {
        if (this.state.showDeleteConfirmationPopup) return
        var arr = []
        var users = []
        for (let user of this.props.users) {
            if (!!user.role) {
                if (user.role.id === role._id) {
                    arr.push(user.name)
                    users.push(user)
                }
            }
        }
        var additionalString = ""
        if (arr.length > 0) {
            additionalString = ` This will also remove their role from the following staff: ${arr.join(', ')}`
        }
        const contentString = "Delete role '" + role.name + "'?" + additionalString
        this.setState({
            idToDelete: role._id,
            deleteConfirmContentString: contentString,
            showDeleteConfirmationPopup: true,
            usersToEdit: users
        })
    }

    handleDeleteCancel = () =>
        this.setState({ showDeleteConfirmationPopup: false })

    handleDeleteConfirm = () => {
        const dict = { id: this.state.idToDelete }
        this.props.deleteRole(dict, () => {
            this.callAPIRemoveRoleFromUsers()
        })
    }

    callAPIRemoveRoleFromUsers() {
        let group = new DispatchGroup()
        for (let user of this.state.usersToEdit) {
            const { _id, createdBy, createdAt, name, email, password, remarks } = user
            const role = null
            const dict = { _id, createdBy, createdAt, name, email, role, password, remarks }
            let token = group.enter()
            this.props.editUser(dict, (success) => {
                if (success) {
                    group.leave(token)
                } else {
                    alert('Failure editing user, please try again')
                }
            })
        }
        group.notify(() => { 
            this.setState({ showDeleteConfirmationPopup: false })
            this.props.fetchRoles()
            this.props.onDeleteSuccess()
        })
    }
}

function mapStateToProps({ roles, users }) {
    return { roles, users }
}

var DispatchGroup = (function() {
    var nextId = 0

    function DispatchGroup() {
        var id = ++nextId
        var tokens = new Set()
        var onCompleted = null

        function checkCompleted() {
            if(!tokens.size) {
                if(onCompleted) {
                    onCompleted()
                    console.log('group ' + id + ' completed')
                }
            }
        }

        // the only requirement for this is that it's unique during the group's cycle
        function nextToken() {
            return Date.now() + Math.random()
        }

        this.enter = function () {
            let token = nextToken()
            tokens.add(token)
            console.log('group ' + id + ' enter ' + token)
            return token
        }

        this.leave = function (token) {
            if(!token) throw new Error("'token' must be the value earlier returned by '.enter()'")
            tokens.delete(token)
            console.log('group ' + id + ' leave '+token)
            checkCompleted()
        }

        this.notify = function (whenCompleted) {
            if(!whenCompleted) throw new Error("'whenCompleted' must be defined")
            onCompleted = whenCompleted
            checkCompleted()
        }
    }

    return DispatchGroup;
})()

export default connect(mapStateToProps, actions)(Roles)