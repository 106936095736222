import React, { Component } from "react"
import { BrowserRouter, Route } from "react-router-dom"
import { connect } from "react-redux"
import * as actions from "../actions"

import Landing from "./Landing"
import TwoFAPage from "./TwoFAPage"
import Home from "./Home"
import Bubbly from "./Bubbly"
import FMD from "./fmd-website/FMD"
import DocScanQR from "./home/clients/client-forms/DocScanQR"
import SignaturePad from "./home/clients/client-forms/SignaturePad"

class App extends Component {
    componentDidMount() {
        this.props.fetchUser()
    }

    render() {
        return (
            <div className="container"> 
                <BrowserRouter>
                    <div>
                        <Route exact path="/" component={Landing} />
                        <Route exact path="/2fa" component={TwoFAPage} />
                        <Route exact path="/home" component={Home} />
                        <Route exact path="/bubbly" component={Bubbly} />
                        <Route exact path="/fmd" component={FMD} />
                        <Route path="/scan_document/client/:clientID/record/:recordID/document/:docID" component={DocScanQR} />
                        <Route path="/client_e-signature/client/:clientID/record/:recordID" component={SignaturePad} />
                    </div>
                </BrowserRouter>
            </div>
        )
    } 
}

export default connect(null, actions)(App)

