import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "../../../../actions"

import { format } from "date-fns"
import { PrintComponent, PrintAllComponent } from "./PrintQRComponent"
import QRCode from "react-qr-code"

import { Container, Divider, Header, Icon, Button, Table, TableHeader, TableRow, TableCell, TableHeaderCell, TableBody, Confirm, Popup, Grid, GridRow, Message, CardGroup, Card, CardContent, CardHeader, CardMeta, CardDescription, Image } from 'semantic-ui-react'

class ClientViewDoc extends Component {
    state = { showDocHandoverConfirmationPopup: false, showDocReceivedConfirmationPopup: false, showAuditTrailDetails: false }

    constructor(props) {
        super(props)
        this.handleDocHandoverConfirm = this.handleDocHandoverConfirm.bind(this)
        this.handleDocHandoverCancel = this.handleDocHandoverCancel.bind(this)
        this.handleDocReceivedConfirm = this.handleDocReceivedConfirm.bind(this)
        this.handleDocReceivedCancel = this.handleDocReceivedCancel.bind(this)
        this.handleRefreshClicked = this.handleRefreshClicked.bind(this)
    }

    renderTableRows() {
        if (!!this.props.recordToView) {
            var rows = []
            for (var i = 0; i < this.props.recordToView.docs.length; i++) {
                let doc = this.props.recordToView.docs[i]
                var divs = []
                if (this.state.showAuditTrailDetails) {
                    for (const trail of doc.auditTrail) {
                        var str = trail.status + " at " + format(trail.date, "d MMMM, yyyy 'at' hh:mm a") + " by " + trail.user + ".\n"
                        if (!!trail.initials) {
                            if (trail.status.includes('Returned')) {
                                str = trail.status + " at " + format(trail.date, "d MMMM, yyyy 'at' hh:mm a") + " (e-Signature obtained from client by " + trail.initials + ").\n"
                            } else {
                                str = trail.status + " at " + format(trail.date, "d MMMM, yyyy 'at' hh:mm a") + " (QR code scanned by " + trail.initials + ").\n"
                            }
                        }
                        divs.push(<p style={{ marginTop: 0, marginBottom: 5 }}>{str}</p>)
                    }
                } else {
                    divs = ['...']
                }
                rows.push(
                    <TableRow key={i}>
                        <TableCell collapsing>
                        <PrintComponent clientID={this.props.clientID} recordID={this.props.recordToView.id} doc={doc} displayStr={i+1} />
                        </TableCell>
                        <TableCell collapsing>{i+1}</TableCell>
                        <TableCell>{doc.type}</TableCell>
                        <TableCell>{doc.description}</TableCell>
                        <TableCell>{doc.remarks}</TableCell>
                        <TableCell>{doc.status}</TableCell>
                        <TableCell style={{ whiteSpace: 'pre-line' }}>{divs}</TableCell>
                    </TableRow>
                )
            }
            return rows
        }
    }

    renderTable() {
        if (this.props.recordToView.docs.length === 0) {
            return <h3>No documents to show</h3>
        }
        var str = "Show details"
        if (this.state.showAuditTrailDetails) str = "Hide details"
        return (
            <Table celled striped compact>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell></TableHeaderCell>
                        <TableHeaderCell>No</TableHeaderCell>
                        <TableHeaderCell width='3'>Type</TableHeaderCell>
                        <TableHeaderCell>Description</TableHeaderCell>
                        <TableHeaderCell width='3'>Remarks</TableHeaderCell>
                        <TableHeaderCell width='2'>Status
                            <Popup wide trigger={<Icon style={{ marginLeft: 5 }} circular name="info" size="small" />} position="bottom left">
                                <Grid style={{ paddingLeft: 16, paddingRight: 16 }} centered>
                                <GridRow>
                                    <Header as='h4'>Received</Header>
                                    Document has just been received from the client. Pending print and stick QR code on the document.
                                </GridRow>
                                <GridRow textAlign='center'>
                                    <Header as='h4'>In Processing</Header>
                                    Document has already been handed over to the Audit Team, and they are now processing it.
                                </GridRow>
                                <GridRow textAlign='center'>
                                    <Header as='h4'>Ready to Return</Header>
                                    Document has been received from the Audit Team, and its QR code has already been scanned.
                                </GridRow>
                                <GridRow textAlign='center'>
                                    <Header as='h4'>Returned</Header>
                                    Document has been returned to the client, and the client's e-signature has been received.
                                </GridRow>
                                </Grid>
                            </Popup>
                        </TableHeaderCell>
                        <TableHeaderCell width='3'>Audit Trail <Button style={{ marginLeft: 10 }} basic color="black" size="tiny" onClick={() => this.handleAuditTrailShowDetailsClicked()}>{str}</Button></TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                {this.renderTableRows()}
                </TableBody>
            </Table>
        )
    }

    renderActionButtons() {
        if (this.props.viewOnly) return
        var showHandoverButton = false
        var showReceivedButton = false
        var showGenerateESignButton = false
        var isPartialReturn = false
        for (const doc of this.props.recordToView.docs) {
            if (doc.status === 'Received') {
                showHandoverButton = true
                break
            } else if (doc.status === 'In Processing') {
                showReceivedButton = true
            } else if (doc.status === 'Ready to Return') {
                showGenerateESignButton = true
            }
        }
        var rows = []
        if (showReceivedButton && showGenerateESignButton) isPartialReturn = true
        if (showHandoverButton) {
            rows.push(<Button style={{ marginTop: 20, marginRight: 15 }} color='red' onClick={() => this.handleDocHandoverClicked()}>I have handed over all Documents to Audit Team<Icon style={{ marginLeft: 10 }} name="check" /></Button>)
        } else if (isPartialReturn) {
            rows.push(<Button style={{ marginTop: 20, marginRight: 15 }} color='red' onClick={() => this.handleDocReceivedClicked()}>I have received all Remaining Documents from Audit Team<Icon style={{ marginLeft: 10 }} name="check" /></Button>)
            // rows.push(<Button style={{ marginTop: 20, marginRight: 15 }} color='red' onClick={() => this.handleUnableToGetESignature()}>Unable to get Client's e-Signature<Icon style={{ marginLeft: 10 }} name="exclamation" /></Button>)
        } else if (showReceivedButton) {
            rows.push(<Button style={{ marginTop: 20, marginRight: 15 }} color='red' onClick={() => this.handleDocReceivedClicked()}>I have received all Documents from Audit Team<Icon style={{ marginLeft: 10 }} name="check" /></Button>)
        } else if (showGenerateESignButton) {
            // rows.push(<Button style={{ marginTop: 20, marginRight: 15 }} color='red' onClick={() => this.handleUnableToGetESignature()}>Unable to get Client's e-Signature<Icon style={{ marginLeft: 10 }} name="exclamation" /></Button>)
        }
        return rows
    }

    renderPrintAllQRButton() {
        for (const doc of this.props.recordToView.docs) {
            if (doc.status !== 'Received') {
                return
            } 
        }
        return <PrintAllComponent clientID={this.props.clientID} recordID={this.props.recordToView.id} docs={this.props.recordToView.docs} />
    }

    renderRefreshMessage() {
        if (this.state.showRefreshingStatus) return <Container><Message info content='Refreshing all document status...'/><br /></Container>
        if (this.state.showUpdatingDocStatus) return <Container><Message info content='Updating document status...'/><br /></Container>
    }

    renderSignatureQRCode() {
        for (const doc of this.props.recordToView.docs) {
            if (doc.status === 'Ready to Return') {
                let url = "https://theaccountants.sg/client_e-signature/client/" + this.props.clientID + "/record/" + this.props.recordToView.id
                console.log(url)
                return <div> 
                    <h3 style={{ marginTop: 30, marginBottom: 25 }}>Scan this QR Code with your mobile device to obtain an e-Signature from the client:</h3>
                    <QRCode style={{ marginBottom: 20 }} size={100} value={url} />
                </div>
            }
        }
    }

    renderSignatures() {
        var arr = []
        console.log(this.props.recordToView)
        if (!!this.props.recordToView.signatures) {
            for (const signature of this.props.recordToView.signatures) {
                var items = []
                for (const item of signature.items) {
                    items.push(item.type)
                }
                arr.push(
                    <Card style={{ marginRight: 15 }}>
                        <Image src={signature.signature} wrapped ui={false} />
                        <CardContent>
                        <CardHeader>{signature.name}</CardHeader>
                        <CardMeta><span className='date'>Signed at {format(signature.date, "d MMMM, yyyy 'at' hh:mm a")}</span></CardMeta>
                        <CardDescription>
                            Items returned: {items.join(", ")}
                        </CardDescription>
                        </CardContent>
                        <CardContent extra>
                        Handled by: {signature.user}
                        </CardContent>
                    </Card>
                )
            }
        }
        return (
            <div style={{ marginTop: 50, marginBottom: 50 }}>
                <Divider style={{ marginBottom: 30 }} horizontal><Header as='h4'><Icon name='pencil' />Signatures</Header></Divider>
                <CardGroup>{arr}</CardGroup>
            </div>
        )
    }

    render() {
        return (
            <Container>
                <h3 style={{marginBottom: 35}}>Document Tracking Record created by {this.props.recordToView.user}, on {format(this.props.recordToView.date, "d MMMM yyyy 'at' hh:mm a")}
                   <Popup content="Refresh the Status of Documents" trigger={<Button style={{ marginLeft: 16 }} basic icon='refresh' onClick={this.handleRefreshClicked}></Button>} position="right center" />
                    </h3>
                {this.renderRefreshMessage()}
                <Divider horizontal><Header as='h4'><Icon name='file alternate outline' />Documents</Header></Divider>
                {this.renderTable()}
                {this.renderPrintAllQRButton()}
                {this.renderSignatures()}
                {this.renderSignatureQRCode()}
                {this.renderActionButtons()}
                <Button basic onClick={this.props.onBack}>Back to Records</Button>
                <Confirm open={this.state.showDocHandoverConfirmationPopup} onCancel={this.handleDocHandoverCancel} onConfirm={this.handleDocHandoverConfirm} 
                header="Confirmation" content="Confirm that you have handed over all documents to the Audit Team. This will change the status of all documents from 'Received' to 'In Processing'." cancelButton="Cancel" confirmButton="Confirm"/>
                <Confirm open={this.state.showDocReceivedConfirmationPopup} onCancel={this.handleDocReceivedCancel} onConfirm={this.handleDocReceivedConfirm} 
                header="Confirmation" content="Confirm that you have received all documents from the Audit Team. This will change the status of all documents from 'In Processing' to 'Ready to Return'." cancelButton="Cancel" confirmButton="Confirm"/>
            </Container>
        )
    }
    
    handleRefreshClicked() {
        this.setState({ showRefreshingStatus: true })
        this.props.getClientDocsFromID(this.props.clientID, (success, data) => {
            if (success) {
                this.props.updateClientDocs(data, this.props.recordToView.id)
                this.setState({ showRefreshingStatus: false })
            } else {
                alert('Failure refreshing document status, please try again')
            }
        })
    }

    handleDocHandoverClicked() {
        if (this.state.showHandoverConfirmationPopup) return
        this.setState({ showDocHandoverConfirmationPopup: true })
    }

    handleDocReceivedClicked() {
        if (this.state.showDocReceivedConfirmationPopup) return
        this.setState({ showDocReceivedConfirmationPopup: true })
    }

    handleDocHandoverCancel = () => {
        this.setState({ showDocHandoverConfirmationPopup: false })
    }

    handleDocHandoverConfirm = () => {
        this.updateServerDocStatusForAllDocs('In Processing')
        this.setState({ showDocHandoverConfirmationPopup: false })
    }

    handleDocReceivedCancel = () => {
        this.setState({ showDocReceivedConfirmationPopup: false })
    }

    handleDocReceivedConfirm = () => {
        this.updateServerDocStatusForAllDocs('Ready to Return')
        this.setState({ showDocReceivedConfirmationPopup: false })
    }

    handleGenerateESignature = () => {
        alert('Generate e-Signature for Client: Work in Progress')
    }

    handleAuditTrailShowDetailsClicked = () => {
        this.setState({ showAuditTrailDetails: !this.state.showAuditTrailDetails })
    }

    updateServerDocStatusForAllDocs(status) {
        this.setState({ showUpdatingDocStatus: true })
        var docIDs = []
        for (const doc of this.props.recordToView.docs) {
            docIDs.push(doc.id)
        }
        let dict = { recordID: this.props.recordToView.id, docIDs, status: status, user: this.props.user.name }
        this.props.updateDocumentStatus(this.props.clientID, dict, (success, data) => {
            if (success) {
                // fetch client docs from server
                this.props.updateClientDocs(data, this.props.recordToView.id)
            } else {
                alert('Failure changing document status, please try again')
            }
        })
    }
}

function mapStateToProps({ client, clientDocs, user }) {
    return { client, clientDocs, user }
}

export default connect(mapStateToProps, actions)(ClientViewDoc)

